/* eslint-disable no-useless-escape */
export function getCookie({ name = 'i18n_cookie' }) {
    const matches = document.cookie.match(
        new RegExp(
            `(?:^|; )${name.replace(
                /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
                '\\$1'
            )}=([^;]*)`
        )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie({ name = 'i18n_cookie', value }) {
    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
        value
    )}`;

    updatedCookie += `; path=/; max-age=31536000`;

    document.cookie = updatedCookie;
}

export function getNavigatorLanguage() {
    const navigatorLanguage = navigator.language || navigator.userLanguage;

    // eslint-disable-next-line prefer-destructuring
    return navigatorLanguage.split('-')[0];
}

export function getUserLanguage() {
    const languageCookie = getCookie({});
    let userLanguage = languageCookie || getNavigatorLanguage() || 'en';

    if (userLanguage !== 'ru') userLanguage = 'en';

    if (languageCookie === undefined) setCookie({ value: userLanguage });

    return userLanguage;
}
