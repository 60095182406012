import useJwt from '@/auth/jwt/useJwt';
import ability from '@/libs/acl/ability';
import { initialAbility } from '@/libs/acl//config';

export const decodeJWT = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
    );

    return JSON.parse(jsonPayload);
};

export const userDataKey = 'userData';

export const getUserData = () => JSON.parse(localStorage.getItem(userDataKey));

/**
 * Removes user data from local storage and
 * updates ability to initial data
 */
export const removeUserData = () => {
    localStorage.removeItem(userDataKey);
    ability.update(initialAbility);
};

export const setUserData = (userData) => {
    const stringifiedUserData = JSON.stringify(userData);

    localStorage.setItem(userDataKey, stringifiedUserData);
};

export const isTokenExpired = (token) => {
    let decodedToken;

    try {
        decodedToken = decodeJWT(token);
    } catch (error) {
        return true;
    }

    const tokenExpirityDate = new Date(decodedToken.exp * 1000);
    const currentDate = new Date();

    return currentDate >= tokenExpirityDate;
};
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
    const token = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName);

    if (isTokenExpired(token)) {
        return false;
    }

    return (
        localStorage.getItem(userDataKey) &&
        localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
    );
};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = () => {
    // ? getUserData is function in this same file
    const userData = getUserData();
    if (userData) return '/';
    return { name: 'login' };
};
