import axios from '@axios';
import { endpoints } from '@/utils/api-endpoints';

const { usersRoles } = endpoints;

export default {
    namespaced: true,

    state: {
        roles: [],
    },

    getters: {
        GET_ROLES(state) {
            return state.roles;
        },
    },

    mutations: {
        SET_ROLES(state, roles) {
            state.roles = roles;
        },
    },

    actions: {
        fetchRoles({ commit }, { locale }) {
            const endpoint = `${locale}${usersRoles}`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endpoint)
                    .then((response) => {
                        commit('SET_ROLES', response.data.roles);

                        return resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
    },
};
