/**
 * @param  {Object} params keys and values of search params
 * @returns {Object} URLSearchParams object
 */
export default function (params = {}) {
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== '')
            searchParams.append(key, value);
    });

    return searchParams;
}
