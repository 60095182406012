import axios from '@axios';
import { endpoints } from '@/utils/api-endpoints';

const { subscriptionsList } = endpoints;

export default {
    namespaced: true,

    state: {
        subscriptions: [],
    },

    getters: {
        GET_SUBSCRIPTIONS(state) {
            return state.subscriptions;
        },
    },

    mutations: {
        SET_SUBSCRIPTIONS(state, subscriptions) {
            state.subscriptions = subscriptions;
        },
    },

    actions: {
        fetchSubscriptions({ commit }, { locale }) {
            const endpoint = `${locale}${subscriptionsList}`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endpoint)
                    .then((response) => {
                        commit(
                            'SET_SUBSCRIPTIONS',
                            response.data.subscriptions
                        );

                        return resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
    },
};
