import axios from '@axios';
import { endpoints } from '@/utils/api-endpoints';

const {
    usersList,
    usersShow,
    usersCancelAutoRenewal,
    paymentsRefund,
} = endpoints;

export default {
    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {
        fetchUsers(ctx, { locale, params }) {
            const endPoint = `/${locale}${usersList}`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endPoint, { params })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        fetchUser(ctx, { locale, id }) {
            const endPoint = `/${locale}${usersShow}/${id}`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endPoint)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        cancelAutoRenewalByUserID(ctx, { locale, id }) {
            const endPoint = `/${locale}${usersCancelAutoRenewal}`;

            return new Promise((resolve, reject) => {
                axios
                    .patch(endPoint, { user_id: id })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        makeARefundByTransactionID(ctx, { locale, transaction_uid }) {
            const endPoint = `/${locale}${paymentsRefund}`;

            return new Promise((resolve, reject) => {
                axios
                    .post(endPoint, { transaction_uid })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        setQueueStartAtNow(ctx, { locale, userId }) {
            const endPoint = `/${locale}/admin/users/${userId}/queue/do-now`;

            return new Promise((resolve, reject) => {
                axios
                    .post(endPoint)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
