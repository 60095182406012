export default [
    // *===============================================---*
    // *------------------- USER -------------------------*
    // *===============================================---*
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/pages/Users.vue'),
        meta: {
            resource: 'Users',
            action: 'read',
        },
        children: [
            {
                path: 'list',
                name: 'users-list',
                component: () =>
                    import('@/views/users/users-list/UsersList.vue'),
            },

            {
                path: ':id/view',
                name: 'users-view',
                component: () =>
                    import('@/views/users/users-view/UsersView.vue'),
            },
            {
                path: ':id/edit',
                name: 'users-edit',
                component: () =>
                    import('@/views/users/users-edit/UsersEdit.vue'),
            },
        ],
    },
];
