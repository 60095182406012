export const endpoints = {
    usersList: '/admin/users',
    usersShow: '/admin/users',
    usersRoles: '/admin/users/roles',
    usersCancelAutoRenewal: '/admin/users/subscriptions',

    skinsList: '/admin/items/weapons/skins',
    skinsFile: '/admin/items/weapons/skins/file',
    skinsImage: '/admin/items/weapons/skins/image',

    modelsList: '/admin/items/weapons/models',
    modelsFile: '/admin/items/weapons/models/file',
    modelsImage: '/admin/items/weapons/models/image',

    charactersList: '/admin/items/characters/models',
    charactersFile: '/admin/items/characters/models/file',
    charactersImage: '/admin/items/characters/models/image',

    skyboxList: '/admin/items/skybox',
    skyboxFile: '/admin/items/skybox/file',
    skyboxImage: '/admin/items/skybox/image',

    weaponsGroupsList: '/weapons/groups',

    strategiesList: '/strategy',

    raritiesList: '/admin/items/rarities',

    subscriptionsList: '/subscriptions',

    fileTypesList: '/admin/files/types',

    newDevicesDynamicStatistics: '/admin/devices/statistics',
    activeDevicesDynamicStatistics: '/admin/devices/statistics/sessions',
    trialsDynamicStatistics: '/admin/payments/statistics/trials',
    subscriptionsDynamicStatistics: '/admin/payments/statistics',

    trialsStatistics: '/admin/users/statistics/trials',
    cardsStatistics: '/admin/users/statistics/cards',
    cardsAliveStatistics: '/admin/users/statistics/cards/alive',
    cancelsStatistics: '/admin/users/statistics/cancels',

    bankCardSearch: '/admin/bankcards',

    paymentsRefund: '/admin/payments/refund',
};

export const _ = {};
