import axios from '@axios';
import { endpoints } from '@/utils/api-endpoints';

const { strategiesList } = endpoints;

export default {
    namespaced: true,

    state: {
        strategies: [],
    },

    getters: {
        GET_STRATEGIES(state) {
            return state.strategies;
        },
    },

    mutations: {
        SET_STRATEGIES(state, strategies) {
            state.strategies = strategies;
        },
    },

    actions: {
        fetchStrategies({ commit }, queryParams) {
            const endpoint = `${strategiesList}`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endpoint, { params: queryParams })
                    .then((response) => {
                        commit('SET_STRATEGIES', response.data.strategy);

                        return resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
    },
};
