export default [
    {
        path: '/game-items',
        name: 'game-items',
        component: () => import('@/views/pages/GameItems.vue'),
        meta: {
            resource: 'GameItems',
            action: 'read',
        },
        children: [
            // Skins
            {
                path: 'skins/list',
                name: 'skins-list',
                component: () =>
                    import('@/views/skins/skins-list/SkinsList.vue'),
            },

            {
                path: 'skins/add',
                name: 'skins-add',
                component: () => import('@/views/skins/skins-add/SkinsAdd.vue'),
            },

            {
                path: 'skins/:id/edit',
                name: 'skins-edit',
                component: () =>
                    import('@/views/skins/skins-edit/SkinsEdit.vue'),
            },

            // Models
            {
                path: 'models/list',
                name: 'models-list',
                component: () =>
                    import('@/views/models/models-list/ModelsList.vue'),
            },

            {
                path: 'models/add',
                name: 'models-add',
                component: () =>
                    import('@/views/models/models-add/ModelsAdd.vue'),
            },

            {
                path: 'models/:id/edit',
                name: 'models-edit',
                component: () =>
                    import('@/views/models/models-edit/ModelsEdit.vue'),
            },

            // Characters
            {
                path: 'characters/list',
                name: 'characters-list',
                component: () =>
                    import(
                        '@/views/characters/characters-list/CharactersList.vue'
                    ),
            },

            {
                path: 'characters/add',
                name: 'characters-add',
                component: () =>
                    import(
                        '@/views/characters/characters-add/CharactersAdd.vue'
                    ),
            },

            {
                path: 'characters/:id/edit',
                name: 'characters-edit',
                component: () =>
                    import(
                        '@/views/characters/characters-edit/CharactersEdit.vue'
                    ),
            },

            // Skybox
            {
                path: 'skybox/list',
                name: 'skybox-list',
                component: () =>
                    import('@/views/skybox/skybox-list/SkyboxList.vue'),
            },

            {
                path: 'skybox/add',
                name: 'skybox-add',
                component: () =>
                    import('@/views/skybox/skybox-add/SkyboxAdd.vue'),
            },

            {
                path: 'skybox/:id/edit',
                name: 'skybox-edit',
                component: () =>
                    import('@/views/skybox/skybox-edit/SkyboxEdit.vue'),
            },
        ],
    },
];
