import axios from '@axios';
import getSearchParams from '@/utils/getSearchParams';
import { getEndpointsByGameItemsVariant } from './gameItemsUtils';

export default {
    namespaced: true,

    state: {
        gameItems: {},
    },

    getters: {
        GET_GAME_ITEMS(state) {
            return state.gameItems.data || [];
        },

        GET_GAME_ITEMS_TOTAL(state) {
            return state.gameItems.total || 0;
        },
    },

    mutations: {
        SET_GAME_ITEMS(state, gameItems) {
            state.gameItems = gameItems;
        },
    },

    actions: {
        fetchGameItems(
            { commit },
            { gameItemVariant, locale = 'en', limit = null, page = null }
        ) {
            const params = getSearchParams({ per_page: limit, page });

            const endpoint = `${locale}${
                getEndpointsByGameItemsVariant(gameItemVariant).list
            }`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endpoint, { params })
                    .then((response) => {
                        const { items = {} } = response.data;

                        commit('SET_GAME_ITEMS', items);

                        return resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        postNewGameItem(
            _,
            { gameItemVariant, gameItemData = {}, locale = 'en' }
        ) {
            const endpoint = `${locale}${
                getEndpointsByGameItemsVariant(gameItemVariant).list
            }`;

            return new Promise((resolve, reject) => {
                axios
                    .post(endpoint, gameItemData)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        fetchGameItemById(_, { gameItemVariant, gameItemId, locale = 'en' }) {
            const endpoint = `${locale}${
                getEndpointsByGameItemsVariant(gameItemVariant).list
            }/${gameItemId}`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endpoint)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        editGameItemById(
            _,
            { gameItemVariant, gameItemId, updatedGameItem = {}, locale = 'en' }
        ) {
            const endpoint = `${locale}${
                getEndpointsByGameItemsVariant(gameItemVariant).list
            }/${gameItemId}`;

            return new Promise((resolve, reject) => {
                axios
                    .put(endpoint, updatedGameItem)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        uploadGameItemFileById(
            _,
            { gameItemVariant, bodyFormData = {}, locale = 'en' }
        ) {
            const endpoint = `${locale}${
                getEndpointsByGameItemsVariant(gameItemVariant).file
            }`;
            const headers = { 'Content-Type': 'multipart/form-data' };

            return new Promise((resolve, reject) => {
                axios
                    .post(endpoint, bodyFormData, headers)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteGameItemFileById(
            _,
            { gameItemVariant, fileID = '', locale = 'en' }
        ) {
            const endpoint = `${locale}${
                getEndpointsByGameItemsVariant(gameItemVariant).file
            }/${fileID}`;

            return new Promise((resolve, reject) => {
                axios
                    .delete(endpoint)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        uploadGameItemImageById(
            _,
            { gameItemVariant, bodyFormData = {}, locale = 'en' }
        ) {
            const endpoint = `${locale}${
                getEndpointsByGameItemsVariant(gameItemVariant).image
            }`;
            const headers = { 'Content-Type': 'multipart/form-data' };

            return new Promise((resolve, reject) => {
                axios
                    .post(endpoint, bodyFormData, headers)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
