import { endpoints } from '@/utils/api-endpoints';

const {
    skinsList,
    skinsFile,
    skinsImage,
    modelsList,
    modelsFile,
    modelsImage,
    charactersList,
    charactersFile,
    charactersImage,
    skyboxList,
    skyboxFile,
    skyboxImage,
} = endpoints;

export const gameItemsVariants = ['skins', 'models', 'characters', 'skybox'];

export const gameItemsEndpoints = {
    skins: {
        list: skinsList,
        file: skinsFile,
        image: skinsImage,
    },
    models: {
        list: modelsList,
        file: modelsFile,
        image: modelsImage,
    },
    characters: {
        list: charactersList,
        file: charactersFile,
        image: charactersImage,
    },
    skybox: {
        list: skyboxList,
        file: skyboxFile,
        image: skyboxImage,
    },
};

export const getEndpointsByGameItemsVariant = (gameItemsVariant) =>
    gameItemsEndpoints[gameItemsVariant];

export const gameItemsParams = {
    skins: {
        id: 'skin_id',
    },
    models: {
        id: 'model_id',
    },
    characters: {
        id: 'model_id',
    },
    skybox: {
        id: 'skybox_id',
    },
};

export const getParamsByGameItemsVariant = (gameItemsVariant) =>
    gameItemsParams[gameItemsVariant];
