export const initialAbility = [
    {
        action: 'read',
        subject: 'Auth',
    },
];

export const roles = [
    {
        id: 2,
        name: 'blogger',
        ability: [
            ...initialAbility,
            {
                action: 'read',
                subject: 'Home',
            },
        ],
    },

    {
        id: 3,
        name: 'designer',
        ability: [
            ...initialAbility,
            {
                action: 'read',
                subject: 'Home',
            },
        ],
    },

    {
        id: 4,
        name: 'admin',
        ability: [
            {
                action: 'manage',
                subject: 'all',
            },
        ],
    },

    {
        id: 5,
        name: 'support',
        ability: [
            ...initialAbility,
            {
                action: 'read',
                subject: 'Home',
            },
        ],
    },

    {
        id: 6,
        name: 'marketer',
        ability: [
            ...initialAbility,
            {
                action: 'read',
                subject: 'Home',
            },
        ],
    },

    {
        id: 7,
        name: 'optimizer',
        ability: [
            ...initialAbility,
            {
                action: 'read',
                subject: 'Home',
            },
        ],
    },

    {
        id: 8,
        name: 'financier',
        ability: [
            ...initialAbility,
            {
                action: 'read',
                subject: 'Home',
            },
        ],
    },
];
