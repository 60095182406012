import axios from '@axios';
import getSearchParams from '@/utils/getSearchParams';
import { endpoints } from '@/utils/api-endpoints';

const { trialsStatistics } = endpoints;

export default {
    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {
        fetchTrialsStatistics(_, { locale = 'en', from = null, to = null }) {
            const params = getSearchParams({ from, to });

            const endpoint = `${locale}${trialsStatistics}`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endpoint, { params })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
