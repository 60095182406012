export default [
    // *===============================================---*
    // *---------------- BANK CARD -----------------------*
    // *===============================================---*
    {
        path: '/bankcard/list',
        name: 'bankcard-list',
        component: () =>
            import('@/views/bankcard/bankcard-list/BankCardList.vue'),
        meta: {
            resource: 'Users',
            action: 'read',
        },
    },
];
