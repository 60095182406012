import axios from '@axios';
import getSearchParams from '@/utils/getSearchParams';

export default {
    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {
        fetchDynamicStatistics(
            _,
            {
                locale = 'en',
                scale = null,
                statisticsEndpoint = null,
                date = null,
                timezone = null,
            }
        ) {
            const params = getSearchParams({ date, timezone });

            const endpoint = `${locale}${statisticsEndpoint}/${scale}`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endpoint, { params })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
