import Vue from 'vue';
import VueRouter from 'vue-router';

import { canNavigate } from '@/libs/acl/routeProtection';
import {
    isUserLoggedIn,
    // getUserData,
    removeUserData,
    getHomeRouteForLoggedInUser,
} from '@/auth/utils';

import pages from './routes/pages';
import users from './routes/users';
import gameItems from './routes/game-items';
import statistics from './routes/statistics';
import bankcard from './routes/bankcard';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        ...pages,
        ...users,
        ...gameItems,
        ...statistics,
        ...bankcard,
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
});

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn();

    if (!isLoggedIn) {
        removeUserData();
    }

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: 'login' });

        // If logged in => not authorized
        return next({ name: 'home' });
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        next(getHomeRouteForLoggedInUser());
    }

    return next();
});

export default router;
