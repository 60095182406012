import axios from '@axios';
import { endpoints } from '@/utils/api-endpoints';

const { raritiesList } = endpoints;

export default {
    namespaced: true,

    state: {
        rarities: [],
    },

    getters: {
        GET_RARITIES(state) {
            return state.rarities;
        },
    },

    mutations: {
        SET_RARITIES(state, rarities) {
            state.rarities = rarities;
        },
    },

    actions: {
        fetchRarities({ commit }, { locale = 'en' }) {
            const endpoint = `${locale}${raritiesList}`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endpoint)
                    .then((response) => {
                        commit('SET_RARITIES', response.data.rarities);

                        return resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
    },
};
