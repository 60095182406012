import axios from '@axios';
import getSearchParams from '@/utils/getSearchParams';
import { endpoints } from '@/utils/api-endpoints';

const { cardsStatistics, cardsAliveStatistics, cancelsStatistics } = endpoints;
const statistics = {
    cards: cardsStatistics,
    cardsAlive: cardsAliveStatistics,
    cancels: cancelsStatistics,
};
const getEndpoint = (type) => statistics[type] || null;

export default {
    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {
        fetchStatistics(_, { locale = 'en', type, from = null, to = null }) {
            const params = getSearchParams({ from, to });
            const matchedEndpoint = getEndpoint(type);

            if (!matchedEndpoint) {
                return Promise.reject(new Error('Endpoint not found'));
            }

            const endpoint = `${locale}${matchedEndpoint}`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endpoint, { params })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
