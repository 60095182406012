import axios from '@axios';
import { endpoints } from '@/utils/api-endpoints';

const { fileTypesList } = endpoints;

export default {
    namespaced: true,

    state: {
        fileTypes: [],
    },

    getters: {
        GET_FILE_TYPES(state) {
            return state.fileTypes;
        },
    },

    mutations: {
        GET_FILE_TYPES(state, fileTypes) {
            state.fileTypes = fileTypes;
        },
    },

    actions: {
        fetchFileTypes({ commit }, { locale = 'en' }) {
            const endpoint = `${locale}${fileTypesList}`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endpoint)
                    .then((response) => {
                        const { file_types: fileTypes = [] } = response.data;

                        commit('GET_FILE_TYPES', fileTypes);

                        return resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
    },
};
