export default [
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'Auth',
        },
    },

    {
        path: '/',
        name: 'home',
        component: () => import('@/views/pages/Home.vue'),
        meta: {
            resource: 'Home',
            action: 'read',
            // Title and Breadcrumb`s text should match the i18n key
            pageTitle: 'menu.home',
            breadcrumb: [
                {
                    text: 'menu.home',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/Login.vue'),
        props: true,
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'Auth',
        },
    },

    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/pages/Register.vue'),
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'Auth',
        },
    },

    {
        path: '/twofa',
        name: 'two-fa',
        component: () => import('@/views/pages/TwoFA.vue'),
        props: true,
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'Auth',
        },
    },

    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/pages/ForgotPassword.vue'),
        props: true,
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'Auth',
        },
    },
];
