export default [
    // *===============================================---*
    // *----------------- Statistics ----------------------*
    // *===============================================---*
    {
        path: '/statistics',
        name: 'statistics',
        component: () => import('@/views/pages/Statistics.vue'),
        meta: {
            resource: 'Statistics',
            action: 'read',
        },
        children: [
            {
                path: 'active-devices',
                name: 'statistics-active-devices',
                component: () =>
                    import(
                        '@/views/statistics/statistics-active-devices/StatisticsActiveDevices.vue'
                    ),
            },

            {
                path: 'new-devices',
                name: 'statistics-new-devices',
                component: () =>
                    import(
                        '@/views/statistics/statistics-new-devices/StatisticsNewDevices.vue'
                    ),
            },

            {
                path: 'trials',
                name: 'statistics-trials',
                component: () =>
                    import(
                        '@/views/statistics/statistics-trials/StatisticsTrials.vue'
                    ),
            },

            {
                path: 'trials-dynamic',
                name: 'statistics-trials-dynamic',
                component: () =>
                    import(
                        '@/views/statistics/statistics-trials-dynamic/StatisticsTrialsDynamic.vue'
                    ),
            },

            {
                path: 'subscriptions-dynamic',
                name: 'statistics-subscriptions-dynamic',
                component: () =>
                    import(
                        '@/views/statistics/statistics-subscriptions-dynamic/StatisticsSubscriptionsDynamic.vue'
                    ),
            },

            {
                path: 'cards',
                name: 'statistics-cards',
                component: () =>
                    import(
                        '@/views/statistics/statistics-cards/StatisticsCards.vue'
                    ),
            },

            {
                path: 'cancels',
                name: 'statistics-cancels',
                component: () =>
                    import(
                        '@/views/statistics/statistics-cancels/StatisticsCancels.vue'
                    ),
            },
        ],
    },
];
