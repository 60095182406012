import axios from '@axios';
import { endpoints } from '@/utils/api-endpoints';

const { weaponsGroupsList } = endpoints;

export default {
    namespaced: true,

    state: {
        weaponsGroups: [],
    },

    getters: {
        GET_WEAPONS_GROUPS(state) {
            return state.weaponsGroups;
        },
    },

    mutations: {
        SET_WEAPONS_GROUPS(state, weaponsGroups) {
            state.weaponsGroups = weaponsGroups;
        },
    },

    actions: {
        fetchWeapons({ commit }, { locale }) {
            const endpoint = `/${locale}${weaponsGroupsList}`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endpoint)
                    .then((response) => {
                        commit('SET_WEAPONS_GROUPS', response.data.groups);

                        return resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
    },
};
