import mock from '@/@fake-db/mock';

const data = {
    strategy: [
        {
            id: 0,
            name: 'Без списаний',
        },

        {
            id: 1,
            name: 'Обычные условия',
        },

        {
            id: 2,
            name: 'Специальные условия',
        },
    ],
};

// ------------------------------------------------
// GET: Return strategy
// ------------------------------------------------
mock.onGet('/strategy').reply(() => {
    return [
        200,
        {
            strategy: data.strategy,
        },
    ];
});
