import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import users from '@/views/users/usersStoreModule';
import weapons from '@/views/weapons/weaponsStoreModule';
import roles from '@/views/roles/rolesStoreModule';
import subscriptions from '@/views/subscriptions/subscriptionsStoreModule';
import strategies from '@/views/strategies/strategiesStoreModule';
// eslint-disable-next-line import/extensions
import gameItems from '@/views/game-items/gameItemsStoreModule.js';
import rarities from '@/views/rarities/raritiesStoreModule';
import files from '@/views/files/filesStoreModule';
import dynamicStatistics from '@/views/statistics/statistics-components/statistics-dynamic/dynamicStatisticsStoreModule';
import trials from '@/views/statistics/statistics-trials/trialsStoreModule';
import statistics from '@/views/statistics/statisticsStoreModule';
import bankcard from '@/views/bankcard/bankCardStoreModule';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        'app-users': users,
        'app-weapons': weapons,
        'app-roles': roles,
        'app-subscriptions': subscriptions,
        'app-strategies': strategies,
        'app-game-items': gameItems,
        'app-rarities': rarities,
        'app-files': files,
        'app-dynamic-statistics': dynamicStatistics,
        'app-trials': trials,
        'app-statistics': statistics,
        'app-bankcard': bankcard,
    },
    strict: process.env.DEV,
});
