import axios from '@axios';
import { endpoints } from '@/utils/api-endpoints';

const { bankCardSearch } = endpoints;

export default {
    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {
        fetchBankCardsByMask(ctx, { locale, params }) {
            const endPoint = `/${locale}${bankCardSearch}`;

            return new Promise((resolve, reject) => {
                axios
                    .get(endPoint, { params })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
